var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{ref:"mainForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.ready)?_c('v-row',{staticClass:"geko-form-wrapper"},[_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
          view_data: 'participant_category',
          type: 'select-radio',
          label: 'Kategori Partisipan',
          validation: [],
          option: {
            list_pointer: {
              label: 'label',
              code: 'code',
              display: ['label'],
            },
            default_options: [
              {
                label: 'Personal',
                icon: 'mdi-account-heart',
                code: 'personal',
              },
              {
                label: 'Perusahaan',
                icon: 'mdi-domain',
                code: 'company',
              },
            ],
          },
        }},model:{value:(_vm.formData.participant_category),callback:function ($$v) {_vm.$set(_vm.formData, "participant_category", $$v)},expression:"formData.participant_category"}})],1),(!_vm.isCreate)?_c('v-col',[_c('geko-input',{attrs:{"item":{
          view_data: 'participant_no',
          type: 'text',
          label: 'Kode Partisipan',
        },"disabled":true},model:{value:(_vm.formData.participant_no),callback:function ($$v) {_vm.$set(_vm.formData, "participant_no", $$v)},expression:"formData.participant_no"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
          view_data: 'first_name',
          type: 'text',
          label:
            _vm.formData.participant_category === 'personal'
              ? 'Nama Depan'
              : 'Nama Perusahaan',
          validation: ['required'],
        }},model:{value:(_vm.formData.first_name),callback:function ($$v) {_vm.$set(_vm.formData, "first_name", $$v)},expression:"formData.first_name"}})],1),(_vm.formData.participant_category == 'personal')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
          view_data: 'last_name',
          type: 'text',
          label: 'Nama Belakang',
          validation: [],
        }},model:{value:(_vm.formData.last_name),callback:function ($$v) {_vm.$set(_vm.formData, "last_name", $$v)},expression:"formData.last_name"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
          view_data: 'email',
          type: 'text',
          label: 'Email',
          validation: ['required', 'email'],
        }},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          view_data: 'join_year',
          label: 'Tahun Bergabung',
          type: 'select',
          validation: ['required'],
          option: {
            list_pointer: {
              label: 'label',
              code: 'code',
              display: ['label'],
            },
            default_options: [
              {
                label: '2020',
                code: 2020,
              },
              {
                label: '2021',
                code: 2021,
              },
              {
                label: '2022',
                code: 2022,
              },
              {
                label: '2023',
                code: 2023,
              },
              {
                label: '2024',
                code: 2024,
              },
            ],
          },
        }},model:{value:(_vm.formData.join_year),callback:function ($$v) {_vm.$set(_vm.formData, "join_year", $$v)},expression:"formData.join_year"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          view_data: 'active',
          label: 'Status',
          type: 'select',
          validation: ['required'],
          option: {
            list_pointer: {
              label: 'label',
              code: 'code',
              display: ['label'],
            },
            default_options: [
              {
                label: 'Aktif',
                code: 1,
              },
              {
                label: 'Tidak Aktif',
                code: 0,
              },
            ],
          },
        }},model:{value:(_vm.formData.active),callback:function ($$v) {_vm.$set(_vm.formData, "active", $$v)},expression:"formData.active"}})],1),_c('v-col',{staticClass:"mt-4",attrs:{"lg":"12"}},[_c('div',{staticClass:"d-flex flex-row justify-content-end"},[_c('v-btn',{staticClass:"mr-2",attrs:{"variant":"light"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Batal")]),_c('v-btn',{attrs:{"variant":"success","disabled":_vm.loading,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.isCreate ? "Tambah" : "Perbarui")+" Data ")])],1)])],1):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }